var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-0 ma-0"},[_c('v-card',{staticClass:"pa-0 ma-0",staticStyle:{"background-color":"var(--v-background-base) !important"},attrs:{"flat":""}},[_c('v-toolbar',{staticClass:"pa-0 ma-0",attrs:{"color":"greyBase","dark":"","extended":!_vm.$vuetify.breakpoint.mobile,"flat":"","extension-height":"55"}},[_c('v-toolbar-title',[_c('b',{staticClass:"mr-3"},[_vm._v("Stock Management: Files")])])],1),_c('v-card',{staticClass:"mx-auto",style:({
                'margin-top': !_vm.$vuetify.breakpoint.mobile ? '-64px' : '0px',
                'border-radius': !_vm.$vuetify.breakpoint.mobile ? '50px' : '0px',
            }),attrs:{"max-width":!_vm.$vuetify.breakpoint.mobile ? '94vw' : '100%'}},[_c('v-card-text',{style:({
                    height: !_vm.$vuetify.breakpoint.mobile ? '90vh' : '90vh',
                    'overflow-y': 'auto',
                })},[_c('v-tabs',{staticClass:"mb-1 pb-1 mt-0 pt-0",attrs:{"show-arrows":"","height":"40px"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabs),function(tab){return _c('v-tab',{key:tab,attrs:{"href":`#${tab}`}},[_vm._v(" "+_vm._s(tab)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',{attrs:{"value":"Stock Files"}},[_c('PO')],1),_c('v-tab-item',{attrs:{"value":"CSV Mapping"}},[_c('CSVMapping')],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }